<template>
    <div class="revenue-summary" >
       <ul class="data-list">
            <li v-for="(item,index) in renderList"
                :class="itemClass(item.filed)"
                @click="changeChartDate(item)"
                :key="filed">
                <p class="data-name">
                    {{item.name}}
                    <i class="remark-icon"
                       v-if="index < 6"
                    @click="showRemark(index,item)"></i>
                </p>
                <p class="time-data"><span v-if="showUnit(item)">￥</span>{{item.time_data=='.00'?'0.00':item.time_data}}</p>
                <p class="month-data" v-if="item.month_data">
                    本月<span v-if="showUnit(item)">￥</span>{{item.month_data=='.00'?'0.00':item.month_data}}
                    <!-- separateNum -->
                    <!-- valueKbit -->
                </p>
            </li>
        </ul>
        <remark-window v-if="isShowRemark" :remarkData="remarkData" @closeWindow="isShowRemark=false"></remark-window>
    </div>
</template>

<script>
	export default {
		name:"revenue-summary",
		props:{
			data:{
				type:Object,
				default:() =>{
				}
			},
			timeType:{
				type:Number,
				default:1
			},
			chartParams:{
				type:Object,
				default:()=>{}
			},
		},
		components:{
			'remark-window':() => import('@/components/remark-window'),
		},
		data(){
			return {
				selectTime:null,
				currentMonth:null,
				//渲染列表
				renderList:[],
				//字段名列表
				dictionaryList:[
					{name:'总营业额',remark:'营业买单消费的总金额',filed:'total_turnover'},
					{name:'折扣赠送',remark:'折扣赠送 = 折扣金额 + 赠送金额',filed:'discount_gift'},
					{name:'净营业额',remark:'净营业额 = 总营业额 - 总折扣赠送金额',filed:'net_turnover'},
					{name:'现金流收入',remark:'总现金流支付的收入<br/>（营业现金流消费金额+会员充值金额）',filed:'cash_income'},
					{name:'客流量',remark:'当前门店产生主项消费的客人数量<br/>（数据取值依照除加钟外的主项消费总数量）',filed:'passenger_flow'},
					{name:'项目总个数',remark:'当前门店所有主项消费的总数量<br/>（数据取值参考门店后台软件项目明细中折算率的规则）',filed:'main_item_points'},
					{name:'会员充值总额',remark:'',filed:'member_recharge'},
					{name:'新办会员',remark:'',filed:'new_member'},
					{name:'会员续充',remark:'',filed:'member_refill'},
				],
                //显示说明弹窗
				isShowRemark:false,
				remarkData:null,
			}
		},
    filters: {
          // 用逗号分隔开
          separateNum(value) {
            let result="";
            if(!value || value == '.00'||value == '0.00'){
              result = "0.00"
            }else{
              value = parseFloat(value)
              result=(value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
            return result
          }
      },
		watch:{
			data:{
				deep:true,
				handler:'getRenderList',
			},
		},
		computed:{
			itemClass(){
				let {chartParams} = this
				return function(filed){
					return chartParams&&chartParams.filed === filed?'current':''
				}
			},
			showUnit(){
				return function(item){
					let {filed} = item
					if(filed == 'passenger_flow'
							|| filed == 'main_item_points'
							|| filed == 'new_member'){
						return false
					}
					return true
				}
			},
		},
		created(){
			this.getRenderList()
		},
		methods:{
			/**
			 * 生成渲染列表
			 */
			getRenderList(){
				let {data,timeType,dictionaryList} = this
				let selectTime = data.select_time_data
				let currentMonth = timeType < 3?this.data.current_month_data:null
				let renderList = []
				for(let dicItem of dictionaryList){
					let {name,filed,remark} = dicItem
					let time_data = selectTime[filed]
					let month_data = ! ! currentMonth?currentMonth[filed]:0
					let renderItem = {
						name,
						time_data,
						month_data,
						filed,
                        remark
					}
					renderList.push(renderItem)
				}
				this.renderList = renderList
				this.$emit('changeChartDate',renderList[0])
			},

			/**
			 * 切换走势图数据
			 */
			changeChartDate(item){
				this.currentType = item.filed
				this.$emit('changeChartDate',item)
			},

			/**
             * 显示字段备注
			 */
			showRemark(index,item){
				this.remarkData = item
				this.isShowRemark = true
            }
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .revenue-summary{
        background:#FFFFFF;
        min-height: 400px;
    }

    .data-list{
        display:flex;
        flex-wrap:wrap;
    }

    .data-list li{
        padding:20px 20px 30px;
        border-bottom:1px solid #EEEEEE;
        width:33.33vw;
        text-align:left;

        .data-name{
            line-height:50px;
            font-size:30px;
            display:flex;
            align-items:center;
            white-space:nowrap;
        }

        .remark-icon{
            display:inline-block;
            width:24px;
            height:24px;
            background:url('~@/assets/images/remark-icon.png') no-repeat center/cover;
            border:10px solid transparent;
            margin:-2px 0 0;
        }

        .time-data{
            font-size:32px;
            font-weight:bold;
            line-height:40px;
        }

        .month-data{
            font-size:24px;
            white-space:nowrap;
        }

        .data-list li p{
            max-width:100%;
            @extend %ellipsis;
        }

        &.current{
            color:$high-light-color;

            p:last-child{
                position:relative;
                &:after{
                    content:'';
                    position:absolute;
                    bottom:-32px;
                    left:0;
                    right:0;
                    background:$high-light-color;
                    height:4px;
                }
            }
        }
    }

</style>
